import React from "react";
import Page from "./../../components/Page";
import Section from "./../../components/Section";
import SEO from "./../../components/SEO";

import Title, { SubTitle } from "../../components/releaseNotes/Title";
import Header from "../../components/releaseNotes/Header";
import Feature from "../../components/releaseNotes/Feature";
import VideoView from "../../components/releaseNotes/VideoView";
import cornerstone2 from "../../images/3p9-cornerstone-3d-2.0.png";
import segmentation from "../../images/3p9-3d-seg.png";
import microscopy from "../../images/3p9-wsi.png";
import ui from "../../images/3p9-component-library.png";
import panels from "../../images/3p9-panels.png";
import parametric from "../../images/3p9-parametric-map.png";

import MarkdownRenderer from "../../components/MarkdownRenderer";

const ChangeLog = `
* feat: Add interleaved HTJ2K and volume progressive loading ([PR#4276](https://github.com/OHIF/Viewers/pull/4276))
* fix: Cobb angle not working in basic-test mode and open contour ([PR#4280](https://github.com/OHIF/Viewers/pull/4280))
* fix: webpack import bugs showing warnings on import ([PR#4265](https://github.com/OHIF/Viewers/pull/4265))
* fix: Measurement Tracking: Various UI and functionality improvements ([PR#4481](https://github.com/OHIF/Viewers/pull/4481))
* fix: Have an addIcon that adds to both ui and ui-next ([PR#4490](https://github.com/OHIF/Viewers/pull/4490))
* fix(styles): several fixes for different styles ([PR#4483](https://github.com/OHIF/Viewers/pull/4483))
* feat(ui) updated component font sizes and design component docs ([PR#4486](https://github.com/OHIF/Viewers/pull/4486))
* fix(bugs): Update dependencies and enhance UI components ([PR#4478](https://github.com/OHIF/Viewers/pull/4478))
* fix(style): worklist shifting ([PR#4477](https://github.com/OHIF/Viewers/pull/4477))
* fix(tmtv): fix toggle one up weird behaviours ([PR#4473](https://github.com/OHIF/Viewers/pull/4473))
* fix: build ([PR#4471](https://github.com/OHIF/Viewers/pull/4471))
* fix(JSONScript) update name of the script in the comments to follow renaming ([PR#4441](https://github.com/OHIF/Viewers/pull/4441))
* OHIF with Cornerstone3D 2.0 ([PR#4347](https://github.com/OHIF/Viewers/pull/4347))
* fix(ui) Study Browser small spacing changes ([PR#4469](https://github.com/OHIF/Viewers/pull/4469))
* fix(sr): load existing point, if there is 2nd point in renderableData (Fix rotation in arrow annotation) ([PR#4356](https://github.com/OHIF/Viewers/pull/4356))
* fix(ui): show ui notification on displaySet load error ([PR#4447](https://github.com/OHIF/Viewers/pull/4447))
* fix(ui): display error in ui while loading seg ([PR#4433](https://github.com/OHIF/Viewers/pull/4433))
* fix(packages): http-proxy-middleware vulnerability ([PR#4443](https://github.com/OHIF/Viewers/pull/4443))
* fix(dependent): high priority vulnerabilities ([PR#4396](https://github.com/OHIF/Viewers/pull/4396))
* feat(new-study-panel): default to list view for non thumbnail series, change default fitler to all, and add more menu to thumbnail items with a dicom tag browser ([PR#4417](https://github.com/OHIF/Viewers/pull/4417))
* fix(tmtv): prevent fusion row in tmtv from getting inverted unexpectedly  ([PR#4420](https://github.com/OHIF/Viewers/pull/4420))
* feat(SR): SCOORD3D point annotations support for stack viewports ([PR#4315](https://github.com/OHIF/Viewers/pull/4315))
* fix(typo): type in fourup preset ([PR#4426](https://github.com/OHIF/Viewers/pull/4426))
* fix(createReport): early return on cancel in prompt ([PR#4243](https://github.com/OHIF/Viewers/pull/4243))
* fix(hp-presets): select the active displaySet when toggling an HP preset, not a random one from the series panel ([PR#4365](https://github.com/OHIF/Viewers/pull/4365))
* fix(tools): check if seriesNumber is an undefined properly ([PR#4338](https://github.com/OHIF/Viewers/pull/4338))
* fix(3d-viewport): exception was being thrown and 3d viewpot not getting resized. ([PR#4366](https://github.com/OHIF/Viewers/pull/4366))
* feat(hangingProtocols): added selection of the HangingProtocol stage from the url ([PR#4310](https://github.com/OHIF/Viewers/pull/4310))
* feat(removeButton) : add remove button from toolbar option ([PR#4408](https://github.com/OHIF/Viewers/pull/4408))
* fix(dicomjson): Update getUIDsFromImageID to work with json data source + update getDisplaySetImageUIDs to work with mixed sop class json   ([PR#4322](https://github.com/OHIF/Viewers/pull/4322))
* fix(updateIndex): getNumberOfSlices is defined when used with a 3D viewport ([PR#4424](https://github.com/OHIF/Viewers/pull/4424))
* ui(component): Added new Toast (Sonner) component ([PR#4412](https://github.com/OHIF/Viewers/pull/4412))
* docs(ui): add base for ui component showcase in docs ([PR#4414](https://github.com/OHIF/Viewers/pull/4414))
* fix(auth): oidc-react-issue ([PR#4410](https://github.com/OHIF/Viewers/pull/4410))
* fix(fossa): update fossa to track licenses correctly ([PR#4411](https://github.com/OHIF/Viewers/pull/4411))
* ui(component): Adds flexible DataRow component ([PR#4399](https://github.com/OHIF/Viewers/pull/4399))
* feat(tours): freeze versions and add licensings doc ([PR#4407](https://github.com/OHIF/Viewers/pull/4407))
* feat(tours): Add interactive onboarding guides to OHIF ([PR#4406](https://github.com/OHIF/Viewers/pull/4406))
* fix(Select): select clear button ([PR#4398](https://github.com/OHIF/Viewers/pull/4398))
* fix: 🐛 Fix imports for ui-next ([PR#4394](https://github.com/OHIF/Viewers/pull/4394))
* ui(dropdown menu): Added DropdownMenu component in ui-next ([PR#4387](https://github.com/OHIF/Viewers/pull/4387))
* ui(segmentation): Design Prototype for split panel component ([PR#4378](https://github.com/OHIF/Viewers/pull/4378))
* fix(ui): Fixed study component open and closed feedback in Studies panel ([PR#4384](https://github.com/OHIF/Viewers/pull/4384))
* refactor(config): Update AWS S3 Static wado server URLs ([PR#4381](https://github.com/OHIF/Viewers/pull/4381))
* docs(mobile): IOS & Android Debugging Guides ([PR#4371](https://github.com/OHIF/Viewers/pull/4371))
* feat(toolbar): enable extensions to change toolbar button sections ([PR#4367](https://github.com/OHIF/Viewers/pull/4367))
* feat(studies-panel): New OHIF study panel - under experimental flag ([PR#4254](https://github.com/OHIF/Viewers/pull/4254))
* fix: Add kheops integration into OHIF v3 again ([PR#4345](https://github.com/OHIF/Viewers/pull/4345))
* fix: 🐛 SeriesInstanceUID fallback + update retrieve metadata filtered to check for lazy ([PR#4346](https://github.com/OHIF/Viewers/pull/4346))
* Test: Playwright tests for tmtv ([PR#4331](https://github.com/OHIF/Viewers/pull/4331))
* feat: Add CS3D WSI and Video Viewports and add annotation navigation for MPR ([PR#4182](https://github.com/OHIF/Viewers/pull/4182))
* fix(roundNumber): handle negative numbers properly ([PR#4336](https://github.com/OHIF/Viewers/pull/4336))
* fix: unexpected mpr measurements ([PR#4332](https://github.com/OHIF/Viewers/pull/4332))
* fix(ui): Tailwind build errors ([PR#4329](https://github.com/OHIF/Viewers/pull/4329))
* fix(url): series query param filtering ([PR#4328](https://github.com/OHIF/Viewers/pull/4328))
* feat(ui): Created design and added core components for ui-next ([PR#4324](https://github.com/OHIF/Viewers/pull/4324))
* fix: customization types ([PR#4321](https://github.com/OHIF/Viewers/pull/4321))
* fix: ip SSRF improper categorization ([PR#4319](https://github.com/OHIF/Viewers/pull/4319))
* fix(ui): remove border-border class ([PR#4317](https://github.com/OHIF/Viewers/pull/4317))
* fix(dicom): Update multiframe DICOM JSON parsing for correct image ID generation ([PR#4307](https://github.com/OHIF/Viewers/pull/4307))
* feat(pmap): added support for parametric map ([PR#4284](https://github.com/OHIF/Viewers/pull/4284))
* feat(SR): text structured report (TEXT, CODE, NUM, PNAME, DATE, TIME and DATETIME) ([PR#4287](https://github.com/OHIF/Viewers/pull/4287))
* docs(resources) Update resources.md in Docs ([PR#4290](https://github.com/OHIF/Viewers/pull/4290))
* docs(tools): add listener helpful tip for migration ([PR#4285](https://github.com/OHIF/Viewers/pull/4285))
* fix(image-orientation): Prevent incorrect orientation marker display for single-slice US images ([PR#4275](https://github.com/OHIF/Viewers/pull/4275))
* fix: the start/end command in TMTV for the ROIStartEndThreshold tools ([PR#4281](https://github.com/OHIF/Viewers/pull/4281))
* feat(auth): Add Authorization Code Flow and new Keycloak recipes with new video tutorials ([PR#4234](https://github.com/OHIF/Viewers/pull/4234))
* fix: Tests run against e2e config for both playwright and older tests ([PR#4283](https://github.com/OHIF/Viewers/pull/4283))
* fix: stdValue in TMTV mode ([PR#4278](https://github.com/OHIF/Viewers/pull/4278))
* feat(sort): custom series sort in study panel ([PR#4214](https://github.com/OHIF/Viewers/pull/4214))
* test(weekly): crosshair tests ([PR#4273](https://github.com/OHIF/Viewers/pull/4273))
* docs : Removed deprecated useBulkDataURI ([PR#4272](https://github.com/OHIF/Viewers/pull/4272))
* feat(studyPrefetcher): Study Prefetcher ([PR#4206](https://github.com/OHIF/Viewers/pull/4206))
* Add prettier tailwind ([PR#4269](https://github.com/OHIF/Viewers/pull/4269))
* fix(cli): missing js ([PR#4268](https://github.com/OHIF/Viewers/pull/4268))
* reset .js extension in import ([PR#4267](https://github.com/OHIF/Viewers/pull/4267))
* fix(orthanc): Correct bulkdata URL handling and add configuration example PDF ([PR#4262](https://github.com/OHIF/Viewers/pull/4262))
* test(hyrdation): weekly tests PR ([PR#4263](https://github.com/OHIF/Viewers/pull/4263))
* test(improve-setup): sharding configuration, allow accessing cornerstone methods and events in tests, and add types ([PR#4255](https://github.com/OHIF/Viewers/pull/4255))
* feat(HP): Frame View HP ([PR#4235](https://github.com/OHIF/Viewers/pull/4235))
* fix(CustomViewportOverlay): pass accurate data to Custom Viewport Functions ([PR#4224](https://github.com/OHIF/Viewers/pull/4224))
* test: Unit test was failing ([PR#4249](https://github.com/OHIF/Viewers/pull/4249))
* fix(code): remove console log ([PR#4248](https://github.com/OHIF/Viewers/pull/4248))
* fix(studybrowser): Differentiate recent and all in study panel based on a provided time period ([PR#4242](https://github.com/OHIF/Viewers/pull/4242))
* feat: customization service append and customize functionality should run once ([PR#4238](https://github.com/OHIF/Viewers/pull/4238))
* fix: Allow the mode setup/creation to be async, and provide a few more values to extension/app config/mode setup. ([PR#4016](https://github.com/OHIF/Viewers/pull/4016))
* fix: Use correct external URL for rendered responses with relative URI ([PR#4236](https://github.com/OHIF/Viewers/pull/4236))
* fix: Re-enable hpScale module ([PR#4237](https://github.com/OHIF/Viewers/pull/4237))
* fix(cli): version txt had a new line which it should not ([PR#4233](https://github.com/OHIF/Viewers/pull/4233))
* fix(sr): rendering issue by running loadSR before updateSR ([PR#4226](https://github.com/OHIF/Viewers/pull/4226))
* fix(pdf-viewport): Allow Drag and Drop on PDF Viewport ([PR#4225](https://github.com/OHIF/Viewers/pull/4225))
* docs: broken link to DICOM JSON minimum requirements on DICOM JSON documentation page ([PR#4228](https://github.com/OHIF/Viewers/pull/4228))
* feat: Add customization merge, append or replace functionality ([PR#3871](https://github.com/OHIF/Viewers/pull/3871))
* tests(increase-coverage): weekly tests PR  ([PR#4221](https://github.com/OHIF/Viewers/pull/4221))
* docs(faq, migrations, orthanc): Add FAQ for dynamic measurements, migration guides, and fix orthanc docker ([PR#4217](https://github.com/OHIF/Viewers/pull/4217))
* fix(window-level): move window level region to more tools menu ([PR#4215](https://github.com/OHIF/Viewers/pull/4215))
* tests(increase-coverage): weekly tests PR ([PR#4202](https://github.com/OHIF/Viewers/pull/4202))
* fix: get direct url pixel data should be optional for video ([PR#4152](https://github.com/OHIF/Viewers/pull/4152))
* fix(seg): maintain algorithm name and algorithm type when DICOM seg is exported or downloaded ([PR#4203](https://github.com/OHIF/Viewers/pull/4203))
* fix(hydration): Maintain the same slice that the user was on pre hydration in post hydration for SR and SEG. ([PR#4200](https://github.com/OHIF/Viewers/pull/4200))
* feat(window-level-region): add window level region tool ([PR#4127](https://github.com/OHIF/Viewers/pull/4127))
* fix(tmtv): crosshairs should not have viewport indicators ([PR#4197](https://github.com/OHIF/Viewers/pull/4197))
* fix(seg): should be able to navigate outside toolbox and come back later ([PR#4196](https://github.com/OHIF/Viewers/pull/4196))
* fix: segmentation creation and segmentation mode viewport rendering ([PR#4193](https://github.com/OHIF/Viewers/pull/4193))
* fix(docker): docker build was broken because of imports ([PR#4192](https://github.com/OHIF/Viewers/pull/4192))
* fix(tmtv): side panel crashing when activeToolOptions is not an array ([PR#4189](https://github.com/OHIF/Viewers/pull/4189))
* fix(queryparam): set all query params to lowercase by default ([PR#4190](https://github.com/OHIF/Viewers/pull/4190))
* feat(hp): Add displayArea option for Hanging protocols and example with Mamo ([PR#3808](https://github.com/OHIF/Viewers/pull/3808))
* fix(contour): set renderFill to false for contour ([PR#4186](https://github.com/OHIF/Viewers/pull/4186))
* fix(ultrasound): Upgrade cornerstone3D version to resolve coloring issues ([PR#4181](https://github.com/OHIF/Viewers/pull/4181))
* feat(measurements): show untracked measurements in measurement panel under additional findings ([PR#4160](https://github.com/OHIF/Viewers/pull/4160))
* fix(rt): dont convert to volume for RTSTRUCT ([PR#4157](https://github.com/OHIF/Viewers/pull/4157))
* feat(ui): move to React 18 and base for using shadcn/ui ([PR#4174](https://github.com/OHIF/Viewers/pull/4174))
* feat(types): typed app config ([PR#4171](https://github.com/OHIF/Viewers/pull/4171))
* fix(crosshairs): reset angle, position, and slabthickness for crosshairs when reset viewport tool is used ([PR#4113](https://github.com/OHIF/Viewers/pull/4113))
* fix: dicom json for orthanc by Update package versions for @cornerstonejs dependencies ([PR#4165](https://github.com/OHIF/Viewers/pull/4165))
* test(playwright): move readme, add video tutorial ([PR#4156](https://github.com/OHIF/Viewers/pull/4156))
* feat(test): Playwright testing integration ([PR#4146](https://github.com/OHIF/Viewers/pull/4146))
* fix(HangingProtocol): fix hp when unsupported series load first ([PR#4145](https://github.com/OHIF/Viewers/pull/4145))
* feat(rt): allow rendering of points in RT Struct ([PR#4128](https://github.com/OHIF/Viewers/pull/4128))
* fix(segmentation): Address issue where segmentation creation failed on layout change ([PR#4153](https://github.com/OHIF/Viewers/pull/4153))
* fix(stack-invalidation): Resolve stack invalidation if metadata invalidated ([PR#4147](https://github.com/OHIF/Viewers/pull/4147))
* fix(select): utilize react portals for select component ([PR#4144](https://github.com/OHIF/Viewers/pull/4144))
* fix(tmtv): threshold was crashing the side panel ([PR#4119](https://github.com/OHIF/Viewers/pull/4119))
* fix: 🐛 Overflow scroll list menu based on screen hight ([PR#4123](https://github.com/OHIF/Viewers/pull/4123))
* test(visual-regression): Add percy tests and improve coverage ([PR#4065](https://github.com/OHIF/Viewers/pull/4065))
* fix(auth): bind handleUnauthenticated to correct context ([PR#4120](https://github.com/OHIF/Viewers/pull/4120))
* fix(DicomJSONDataSource): Fix series filtering ([PR#4092](https://github.com/OHIF/Viewers/pull/4092))
* feat(typings): Enhance typing support with withAppTypes and custom services throughout OHIF ([PR#4090](https://github.com/OHIF/Viewers/pull/4090))
* fix(bugs): enhancements and bugs in several areas ([PR#4086](https://github.com/OHIF/Viewers/pull/4086))
* fix(rt): enhanced RT support, utilize SVGs for rendering. ([PR#4074](https://github.com/OHIF/Viewers/pull/4074))
`;

function ReleaseNotes() {
  return (
    <Page>
      <SEO title="OHIF v3.9 | OHIF" />
      <Section className="mx-auto">
        <Title title="OHIF Viewer v3.9" secondary="November 11, 2024" />

        <div className="mt-10">
          <Header />
        </div>
        <div className="flex flex-col space-y-32 mt-12 ">
          <div>
            <div id="overview">
              <SubTitle title={"Overview Video"} />
              <VideoView src="https://www.youtube.com/embed/qE8O7HazDEM?si=UmywY26-y95Ua2J-" />
            </div>
          </div>
          <div id="new-features">
            <SubTitle title={"New Features"} />
            <div className="space-y-32">
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}

              <Feature
                position="left"
                title="Introducing Cornerstone3D 2.0"
                imageSrcs={[cornerstone2]}
              >
                <p>
                  Cornerstone3D 2.0 marks a significant evolution, introducing
                  powerful tools for handling complex imaging data and enhancing
                  compatibility with modern development frameworks. Key updates
                  include Viewport-Centric Segmentation for precise control and
                  multi-viewport rendering, VoxelManager to cut memory usage in
                  half while optimizing data access, and a modernized
                  architecture supporting TypeScript and ES modules. With
                  integration options for Vite, React, and Vue, Cornerstone3D
                  2.0 is built to support the future of imaging technology.
                  Learn more about these updates in our migration guide.
                </p>
              </Feature>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}

              <Feature
                position="right"
                title="Enhanced Segmentation Support: Now Available in 2D and 3D Views"
                imageSrcs={[segmentation]}
              >
                <p>
                  OHIF Viewer 3.9 elevates segmentation capabilities with
                  Cornerstone3D 2.0 integration, providing robust 2D and 3D
                  segmentation support for various imaging modalities, including
                  ultrasound, mammography, and X-rays. Users can now render and
                  edit Labelmaps within non-volume data and visualize them as
                  surfaces in the 3D viewport. By removing the need for viewport
                  conversion before segmentation, OHIF 3.9 enables quicker
                  segmentation creation and hydration, enhancing workflow
                  efficiency and overall performance.
                </p>
              </Feature>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}

              <Feature
                position="left"
                title="Enhanced Video Viewport with Annotation Support"
              >
                <p>
                  Our latest update brings a robust annotation toolset to the
                  video viewport, aligning it with stack viewports for a
                  seamless analysis experience across both formats. Looking
                  ahead, we’re working to introduce advanced segmentation
                  options for video, including contouring and labelmap
                  segmentation, allowing frame-by-frame region-of-interest
                  visualization. This enhancement unlocks powerful capabilities
                  for time-based imaging analysis. Interested in collaborating
                  to accelerate these features? Contact us to discuss
                  partnership opportunities and help bring these advancements to
                  users even faster.
                </p>
              </Feature>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}

              <Feature
                position="right"
                title="Introducing the New Slide Microscopy Viewport with Cornerstone Tools"
                imageSrcs={[microscopy]}
              >
                <p>
                  Explore our new whole-slide imaging (WSI) viewport, now
                  integrated directly with Cornerstone3D. Previously reliant on
                  an external viewport, OHIF’s WSI capabilities now utilize the
                  embedded DICOM Microscopy Viewer library, thanks to
                  contributions from the Imaging Data Commons team. This
                  integration allows for seamless side-by-side viewing of
                  microscopy and other imaging types within Cornerstone3D,
                  offering a unified analysis environment. We invite you to try
                  out this cohesive setup and share your feedback with us!
                </p>
              </Feature>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <Feature
                position="left"
                title="Enhanced UI/UX in OHIF Viewer: Speed, Flexibility, and Accessibility"
                imageSrcs={[ui]}
              >
                <p>
                  The OHIF Viewer now leverages the latest React 18 features,
                  including concurrent rendering, to deliver faster, smoother
                  interactions and improved performance under heavy workloads.
                  Developers can explore our React migration guides to learn
                  more about enhancing projects with these updates.
                </p>
                <p>
                  Additionally, we're excited to announce @ohif/ui-next, a
                  revamped component library built on Shadcn UI. This transition
                  brings custom-styled, themeable components such as buttons,
                  sliders, and notifications, all designed for stability and
                  flexibility. With improved accessibility and customization
                  options, the new UI library is set to accelerate development
                  and enhance the user experience. Visit our playground to
                  experiment with the latest components and access updated Figma
                  resources.
                </p>
              </Feature>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <Feature
                position="right"
                title="Revamped Panels"
                imageSrcs={[panels]}
              >
                <p>
                  In line with our UI modernization efforts, we’ve reimagined
                  the study browser and measurement/segmentation panels to meet
                  evolving web-based imaging needs. The updated panels now
                  feature a streamlined, compact design with collapsible and
                  sortable sections, improving ease of use for managing large
                  studies. This unified panel structure is also designed with
                  future mobile and tablet support in mind, enhancing
                  accessibility across devices.
                </p>
              </Feature>

              <Feature
                position="left"
                title="Parametric Maps"
                imageSrcs={[parametric]}
              >
                <p>
                  Parametric maps can now be overlaid on primary images,
                  offering insightful AI-driven visualizations such as
                  probability and prediction maps. Users can customize these
                  overlays with options for color mapping, opacity control, and
                  a built-in color legend, making interpretation clear and
                  intuitive. Explore this feature in a sample study for a
                  hands-on experience.
                </p>
              </Feature>

              <Feature
                position="right"
                title="On-Premise Deployment & Authentication"
              >
                <p>
                  Our latest update simplifies on-premise deployments with
                  enhanced security integrations including OAuth and Keycloak
                  support. Step-by-step setup videos guide users through
                  configuring Keycloak, NGINX, and connecting with PACS systems
                  like Orthanc, ensuring robust and secure deployment options.
                </p>
              </Feature>
            </div>
          </div>
          <div id="all-changes"></div>
        </div>
        <div>
          <SubTitle title={"All Changes"} />

          <div className="text-white">
            <MarkdownRenderer text={ChangeLog} />
          </div>
        </div>
      </Section>
    </Page>
  );
}

export default ReleaseNotes;
